import { Component, Vue } from 'vue-property-decorator';
import { CustomerGroup as CustomerGroupEnum } from '@/enums/CustomerGroup';
import { SelectItem } from '@/interfaces/components/SelectItem';
@Component
export default class CustomerGroup extends Vue {
  get customerGroups() {
    return [
      { text: this.$t('customerGroups.default'), value: '' },
      { text: this.$t('customerGroups.defaultInPreparation'), value: 'default_in_preparation' },
      { text: this.$t('customerGroups.subway'), value: CustomerGroupEnum.Subway },
      { text: this.$t('customerGroups.subwayTerminal'), value: CustomerGroupEnum.SubwayTerminal },
      { text: this.$t('customerGroups.subwayFr'), value: CustomerGroupEnum.SubwayFr },
      { text: this.$t('customerGroups.subwayLu'), value: CustomerGroupEnum.SubwayLu },
      { text: this.$t('customerGroups.db'), value: CustomerGroupEnum.DB },
      { text: this.$t('customerGroups.lunchroom'), value: CustomerGroupEnum.Lunchroom },
      { text: this.$t('customerGroups.fbf'), value: CustomerGroupEnum.FoodByFriends },
      { text: this.$t('customerGroups.weilands'), value: CustomerGroupEnum.Weilands },
      { text: this.$t('customerGroups.cinemaxx'), value: CustomerGroupEnum.Cinemaxx },
      { text: this.$t('customerGroups.district'), value: CustomerGroupEnum.District },
      { text: this.$t('customerGroups.slush'), value: CustomerGroupEnum.Slush },
      { text: this.$t('customerGroups.kochloeffel'), value: CustomerGroupEnum.Kochloeffel },
      { text: this.$t('customerGroups.immergruen'), value: CustomerGroupEnum.Immergruen },
      { text: this.$t('customerGroups.myMonza'), value: CustomerGroupEnum.MyMonza },
      { text: this.$t('customerGroups.aspasia'), value: CustomerGroupEnum.Aspasia },
      { text: this.$t('customerGroups.cotidiano'), value: CustomerGroupEnum.Cotidiano },
      { text: this.$t('customerGroups.bigburger'), value: CustomerGroupEnum.Bigburger },
      { text: this.$t('customerGroups.koos'), value: CustomerGroupEnum.KOOS },
      { text: this.$t('customerGroups.deanAndDavid'), value: CustomerGroupEnum.DeanAndDavid },
      { text: this.$t('customerGroups.ottosBurger'), value: CustomerGroupEnum.Ottosburger },
      { text: this.$t('customerGroups.frittenwerk'), value: CustomerGroupEnum.Frittenwerk },
      { text: this.$t('customerGroups.purino'), value: CustomerGroupEnum.Purino },
      { text: this.$t('customerGroups.threeMensKebab'), value: CustomerGroupEnum.ThreeMensKebab },
      { text: this.$t('customerGroups.perfectDay'), value: CustomerGroupEnum.PerfectDay },
      { text: this.$t('customerGroups.hausmanns'), value: CustomerGroupEnum.Hausmanns },
      { text: this.$t('customerGroups.doenerwerk'), value: CustomerGroupEnum.Doenerwerk },
      { text: this.$t('customerGroups.foodlounge'), value: CustomerGroupEnum.Foodlounge },
      { text: this.$t('customerGroups.mado'), value: CustomerGroupEnum.Mado },
      { text: this.$t('customerGroups.nordsee'), value: CustomerGroupEnum.Nordsee },
      { text: this.$t('customerGroups.depot'), value: CustomerGroupEnum.Depot },
      { text: this.$t('customerGroups.jamies'), value: CustomerGroupEnum.Jamies },
      { text: this.$t('customerGroups.burgerKing'), value: CustomerGroupEnum.BurgerKing },
      { text: this.$t('customerGroups.burgerKingD'), value: CustomerGroupEnum.BurgerKingD },
      { text: this.$t('customerGroups.atlantikFisch'), value: CustomerGroupEnum.Atlantik_Fisch },
      { text: this.$t('customerGroups.moschmosch'), value: CustomerGroupEnum.Moschmosch },
      { text: this.$t('customerGroups.kamps'), value: CustomerGroupEnum.Kamps },
      { text: this.$t('customerGroups.coffeeFellows'), value: CustomerGroupEnum.Coffee_Fellows },
      { text: this.$t('customerGroups.hofbrauhaus'), value: CustomerGroupEnum.Hofbrauhaus },
      { text: this.$t('customerGroups.saray'), value: CustomerGroupEnum.Saray },
      { text: this.$t('customerGroups.starbucks'), value: CustomerGroupEnum.Starbucks },
      { text: this.$t('customerGroups.dimatest'), value: CustomerGroupEnum.DIMATEST },
      { text: this.$t('customerGroups.maloa'), value: CustomerGroupEnum.MALOA },
      { text: this.$t('customerGroups.werk5'), value: CustomerGroupEnum.WERK5 },
      { text: this.$t('customerGroups.epi'), value: CustomerGroupEnum.EPI },
      { text: this.$t('customerGroups.iamlove'), value: CustomerGroupEnum.IAMLOVE },
      { text: this.$t('customerGroups.beetsAndRoots'), value: CustomerGroupEnum.BEETS_AND_ROOTS },
      { text: this.$t('customerGroups.beetsAndRootsSecond'), value: CustomerGroupEnum.BEETS_AND_ROOTS_SECOND },
      { text: this.$t('customerGroups.losteria'), value: CustomerGroupEnum.LOSTERIA },
      { text: this.$t('customerGroups.snackElize'), value: CustomerGroupEnum.SNACK_ELIZE },
      { text: this.$t('customerGroups.shiso'), value: CustomerGroupEnum.SHISO },
      { text: this.$t('customerGroups.tabilo'), value: CustomerGroupEnum.TABILO },
      { text: this.$t('customerGroups.tabiloSim'), value: CustomerGroupEnum.TABILO_SIM },
      { text: this.$t('customerGroups.effeGold'), value: CustomerGroupEnum.EFFE_GOLD },
      { text: this.$t('customerGroups.lacantine'), value: CustomerGroupEnum.LACANTINE },
      { text: this.$t('customerGroups.eam'), value: CustomerGroupEnum.EAM },
      { text: this.$t('customerGroups.naupaka'), value: CustomerGroupEnum.NAUPAKA },
      { text: this.$t('customerGroups.sportsbar'), value: CustomerGroupEnum.SPORTSBAR },
      { text: this.$t('customerGroups.cigkoftem'), value: CustomerGroupEnum.CIGKOFTEM },
      { text: this.$t('customerGroups.sushisupply'), value: CustomerGroupEnum.SUSHISUPPLY },
      { text: this.$t('customerGroups.the_ash'), value: CustomerGroupEnum.THE_ASH },
      { text: this.$t('customerGroups.markthalle'), value: CustomerGroupEnum.MARKTHALLE },
      { text: this.$t('customerGroups.sashimi'), value: CustomerGroupEnum.SASHIMI },
      { text: this.$t('customerGroups.bonboncha'), value: CustomerGroupEnum.BONBONCHA },
      { text: this.$t('customerGroups.hulala'), value: CustomerGroupEnum.HULALA },
      { text: this.$t('customerGroups.backwerk_schweiz'), value: CustomerGroupEnum.BACKWERK_SCHWEIZ },
      { text: this.$t('customerGroups.backwerkCh'), value: CustomerGroupEnum.BACKWERK_CH },
      { text: this.$t('customerGroups.backfactory'), value: CustomerGroupEnum.BACKFACTORY },
      { text: this.$t('customerGroups.teedeli'), value: CustomerGroupEnum.TEEDELI },
      { text: this.$t('customerGroups.wohlfuhler'), value: CustomerGroupEnum.WOHLFUHLER },
      { text: this.$t('customerGroups.frittenwerkSecond'), value: CustomerGroupEnum.FRITTENWERK_SECOND },
      { text: this.$t('customerGroups.asiaGourmet'), value: CustomerGroupEnum.ASIA_GOURMET },
      { text: this.$t('customerGroups.ricerepublic'), value: CustomerGroupEnum.RICE_REPUBLIC },
      { text: this.$t('customerGroups.goodbytz'), value: CustomerGroupEnum.GOODBYTZ },
      { text: this.$t('customerGroups.piratenBurger'), value: CustomerGroupEnum.PIRATEN_BURGER },
      { text: this.$t('customerGroups.turAbnahmeRes'), value: CustomerGroupEnum.TUR_ABNAHME_RES },
      { text: this.$t('customerGroups.turAbnahmeSim'), value: CustomerGroupEnum.TUR_ABNAHME_SIM },
      { text: this.$t('customerGroups.tournesol'), value: CustomerGroupEnum.TOURNESOL },
      { text: this.$t('customerGroups.brezelKoenig'), value: CustomerGroupEnum.BREZEL_KOENIG },
      { text: this.$t('customerGroups.vincentVegan'), value: CustomerGroupEnum.VINCENT_VEGAN },
      { text: this.$t('customerGroups.papais'), value: CustomerGroupEnum.PAPAIS },
      { text: this.$t('customerGroups.theCup'), value: CustomerGroupEnum.THE_CUP },
      { text: this.$t('customerGroups.marseilleFoodMarket'), value: CustomerGroupEnum.MARSEILLE_FOOD_MARKET },
      { text: this.$t('customerGroups.olearys'), value: CustomerGroupEnum.OLEARYS },
      { text: this.$t('customerGroups.iceland'), value: CustomerGroupEnum.ICELAND },
      { text: this.$t('customerGroups.tableDot'), value: CustomerGroupEnum.TABLE_DOT },
      { text: this.$t('customerGroups.sevenByBat'), value: CustomerGroupEnum.SEVEN_BY_BAT },
      { text: this.$t('customerGroups.breakBurger'), value: CustomerGroupEnum.BREAK_BURGER },
      { text: this.$t('customerGroups.oakberry'), value: CustomerGroupEnum.OAKBERRY },
      { text: this.$t('customerGroups.wok'), value: CustomerGroupEnum.WOK },
      { text: this.$t('customerGroups.turAutohof'), value: CustomerGroupEnum.TUR_AUTOHOF },
      { text: this.$t('customerGroups.turAbnahmeAutohof'), value: CustomerGroupEnum.TUR_ABNAHME_AUTOHOF },
      { text: this.$t('customerGroups.trz'), value: CustomerGroupEnum.TRZ },
      { text: this.$t('customerGroups.pibosa'), value: CustomerGroupEnum.PIBOSA },
      { text: this.$t('customerGroups.pastaFee'), value: CustomerGroupEnum.PASTA_FEE },
      { text: this.$t('customerGroups.monkeyBurger'), value: CustomerGroupEnum.MONKEY_BURGER },
      { text: this.$t('customerGroups.potatoe'), value: CustomerGroupEnum.POTATOE },
      { text: this.$t('customerGroups.shiangMing'), value: CustomerGroupEnum.SHIANG_MING },
      { text: this.$t('customerGroups.antonAndAnni'), value: CustomerGroupEnum.ANTON_AND_ANNI },
      { text: this.$t('customerGroups.kaisin'), value: CustomerGroupEnum.KAISIN },
      { text: this.$t('customerGroups.dunkin'), value: CustomerGroupEnum.DUNKIN },
      { text: this.$t('customerGroups.sushiTomo'), value: CustomerGroupEnum.SUSHI_TOMO },
    ].sort((a, b) => {
      const textA = String(a.text).toLowerCase();
      const textB = String(b.text).toLowerCase();
      return textA.localeCompare(textB);
    });
  }

  get scopedCustomerGroups() {
    if (this.$isAdmin()) {
      return this.customerGroups;
    }
    if (this.$hasCustomerGroup()) {
      return this.customerGroups.filter((i: SelectItem) => i.value === '' || i.value === this.$getCustomerGroup());
    }

    return [];
  }
}
